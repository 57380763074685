<template>
  <div class="admin">
    <div class="padding_top"></div>

    <b-container>
      <b-row align-h="center">
        <b-col cols="auto">
          <div class="logo_wrapper">
            <img class="logo_img" :src="require('@/assets/img/logo.png')" />
          </div>
        </b-col>
      </b-row>

      <!-- User -->
      <b-row align-v="center" align-h="center" class="main_menu__block">
        <b-col cols="12" md="6">
          <b-row align-h="center" v-for="(link, index) in links" :key="index">
            <button
              @click="to(link.routeName)"
              :class="$route.name == link.routeName ? 'active' : ''"
              class="main_manu_button first"
            >
              {{ link.title }}
            </button>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data: () => {
    return {
      links: [
        {
          title: "Редактор мероприятий",
          routeName: "EventsEditor",
        },
        {
          title: "Регистрации на мероприятия",
          routeName: "EventsRegistrations",
        },
        {
          title: "Редактирование заданий",
          routeName: "TasksEditor",
        },
        {
          title: "Проверка заданий",
          routeName: "CompletedTasks",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["getUser"]),
    to(name) {
      if (this.$route.name != name) this.$router.push({ name });

      this.$bvModal.hide("main_menu");
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
  },
  async mounted() {
    if (!localStorage.getItem("token")) {
      this.$router.push({ name: "Login" });
    } else {
      try {
        this.$store.commit("SET_LOADING", true);

        await this.getUser();

        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$store.commit("SET_LOADING", false);
        console.error(e);
      }
    }
  },
};
</script>

<style lang="scss">
.admin {
  padding-top: 100px;
}
</style>
